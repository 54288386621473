import React from 'react';
import { Link } from 'gatsby';
import { Section, Heading, Container } from 'react-bulma-components';

export default function FourOFour() {
  return (
    <Section size="large">
      <Container>
        <Heading>404 - Was there supposed to be something here?</Heading>
        <Heading subtitle>
          <Link to="/">Take me home</Link>
        </Heading>
      </Container>
    </Section>
  );
}
